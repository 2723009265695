import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  AspectRatio,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

interface ModalProps {
  button: ReactNode
  src: string
}

export const VideoModal: React.FC<ModalProps> = props => {
  const { button, src } = props

  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Box onClick={onOpen}>{button}</Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={0}>
          <ModalBody w='auto' p={2}>
            <AspectRatio ratio={1.8}>
              <iframe
                src={src}
                allowFullScreen
                allow='autoplay; fullscreen; picture-in-picture'
              ></iframe>
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
