import {
  Flex,
  HStack,
  Img,
  SlideFade,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import Image from 'next/image'
import { useRef } from 'react'
import { isIntersecting } from 'utils/intersection'
import { Section } from './section'

export const Institutional = () => {
  const ref = useRef<HTMLDivElement>(null)
  const intersected = isIntersecting(ref)

  return (
    <Section bg={'backgroundAlt'} w={'full'}>
      <SlideFade
        ref={ref}
        in={intersected}
        transition={{ enter: { duration: 0.4 } }}
        style={{ width: '100%' }}
      >
        <VStack spacing={6} w={'full'}>
          <Flex pb={[8, 0]} w={'full'} justify={'center'}>
            <Image
              height={55}
              width={200}
              src={'/images/logos/logo_berrytech.svg'}
              loading={'lazy'}
            />
          </Flex>
          <VStack spacing={3}>
            <Text textAlign={'center'} textStyle='h1'>
              Somos fascinados por empreendedorismo
            </Text>
            <Text textAlign={'center'}>
              Conheça a assessoria que mais cresce no Brasil
            </Text>
          </VStack>
          <Stack
            direction={['column', 'row']}
            justify={'space-evenly'}
            align={'center'}
            spacing={4}
            py={[8, 4]}
            w={'full'}
          >
            <VStack>
              <Text textStyle={'h1'} color={'primary'}>
                +1.000 empresas
              </Text>
              <Text>atendidas</Text>
            </VStack>
            <VStack>
              <Text textStyle={'h1'} color={'primary'}>
                27 estados
              </Text>
              <Text>conquistados</Text>
            </VStack>
            <VStack>
              <Text textStyle={'h1'} color={'primary'}>
                +30 segmentos
              </Text>
              <Text>atendidos</Text>
            </VStack>
          </Stack>
          {/* <PressArticles /> */}
          <HStack w={'full'} justify={'center'}>
            <a
              href='https://www.google.com/partners/agency?id=4777955290'
              target='_blank'
            >
              <Img
                loading={'lazy'}
                style={{ width: '100px', height: '100px' }}
                src='https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg'
              />
            </a>
            <Img
              h={['107px', '107px']}
              maxH={['107px', '107px']}
              src={'/images/logos/metapartnerberrytech.png'}
            />
          </HStack>
        </VStack>
      </SlideFade>
    </Section>
  )
}
