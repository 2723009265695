import { Testimonial } from 'testimonial/entities/testimonial'
import { addCdn } from 'utils/env'

export interface TestimonialCase {
  company: string
  person: string
  avatar: string
  videoUrl?: string
  sales: string
  profit: string
  testimony: string
}

export const testimonialCases: Testimonial[] = [
  {
    account: '' as any,
    company: 'Destak Vidros',
    person: 'Denise Souza',
    avatar: addCdn('/images/testimonials/denise_destak.jpg'),
    videoUrl:
      'https://player.vimeo.com/video/560520103?h=d578d5aa36&amp;badge=1&amp;autopause=0&autoplay=1&amp;player_id=0&amp;app_id=58479',
    sales: '+51%',
    profit: '+39%',
    testimony:
      'Super indico! Sem dúvidas a melhor decisão que tomei em minha empresa foi fechar a parceria com a Berrytech! Equipe extremamente qualificada! Nos mostram onde estão os erros, e ajudam na tomada de decisões. Todo esse comprometimento com a nossa empresa, só nos trazem resultados positivos! Super Satisfeita!',
  },
  {
    account: '' as any,
    company: 'Mercado Doce Mel',
    person: 'Daniel Freitas',
    avatar: addCdn('/images/testimonials/daniel_doce_mel.jpg'),
    sales: '+53%',
    profit: '+22%',
    testimony:
      'Na primeira reunião identificamos despesas que não estávamos considerando e conseguimos reduzir cerca de 2 mil reais do nosso custo operacional.',
  },
  {
    account: '' as any,
    company: 'Kings Auto Center',
    person: 'Gerard H. de Sá',
    avatar: addCdn('/images/testimonials/gerard_kings.jpg'),
    sales: '+45%',
    profit: '+113%',
    testimony:
      'A consultoria tem me ajudado bastante, a empresa está bem mais organizada e com certeza vou ficar mais um bom tempo com a galera da Berrytech.',
    videoUrl:
      'https://player.vimeo.com/video/526149040?h=36f177c033&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  },
  {
    account: '' as any,
    company: 'Hércules Supermercados',
    person: 'Hércules Spirandelli',
    avatar: addCdn('/images/testimonials/hércules.jpg'),
    sales: '+240%',
    profit: '',
    testimony:
      'Conseguimos sair de uma situação de vermelho bem complicada. Levantamos a empresa e hoje estamos em uma situação confortável. Para nós foi um excelente trabalho de dedicação e compromisso!',
    videoUrl:
      'https://player.vimeo.com/video/470584425?autoplay=1&amp;autopause=0&amp;badge=1',
  },
]
