import {
  Box,
  Button,
  HStack,
  Img,
  SimpleGrid,
  SlideFade,
  Text,
  VStack,
} from '@chakra-ui/react'
import { VideoModal } from 'common/video_modal'
import { motion } from 'framer-motion'
import { Testimonial } from 'testimonial/entities/testimonial'
import { firstLettersToUppercase } from 'utils/string'

interface CaseItemProps {
  testimonial: Testimonial
  onPrevious: () => void
  onNext: () => void
  hideTestimony?: boolean
}

export const TestimonialItem: React.FC<CaseItemProps> = props => {
  const { testimonial, onPrevious, onNext } = props

  if (!!!testimonial) return null

  return (
    <>
      <motion.div
        style={{ width: '100%' }}
        initial={{ opacity: 0, offset: 90 }}
        animate={{ opacity: 1, offset: -90 }}
        exit={{ opacity: 0 }}
      >
        <SlideFade in style={{ width: '100%' }}>
          <SimpleGrid columns={[1, 2]} w='full' spacing={6}>
            <VStack flex={1} w={'full'} justify={'center'}>
              <Img
                rounded={'lg'}
                objectFit={'cover'}
                maxH={'250px'}
                minW={'250px'}
                boxShadow={'lg'}
                src={testimonial.avatar}
                loading={'lazy'}
              />
              <HStack spacing={8} w='full' justify={'center'} py={4}>
                <Button variant={'icon'} onClick={onPrevious}>
                  Anterior
                </Button>
                <Button variant={'icon'} onClick={onNext}>
                  Próximo
                </Button>
              </HStack>
            </VStack>
            <VStack flex={2} textAlign='start' alignItems='start'>
              <VStack align={'flex-start'} w='full'>
                <Text textAlign={'left'} textStyle='h1'>
                  {firstLettersToUppercase(testimonial.company.split(' '))}
                </Text>
                <VStack align={'flex-start'} py={4}>
                  {testimonial.sales && (
                    <Text textStyle='h2' color={'accent'}>
                      {testimonial.sales} em Vendas
                    </Text>
                  )}
                  {testimonial.profit && (
                    <Text textStyle='h2' color={'accent'}>
                      {testimonial.profit} em Lucro
                    </Text>
                  )}
                </VStack>
                <HStack w={'full'} pt={2}>
                  {!props.hideTestimony && (
                    <Text fontSize={['1rem', '1.35rem']}>
                      {testimonial.testimony.startsWith('"') &&
                      testimonial.testimony.endsWith('"')
                        ? testimonial.testimony
                        : `"${testimonial.testimony}"`}
                    </Text>
                  )}
                  {props.hideTestimony && (
                    <Text fontSize={['0rem', '1.35rem']}>
                      ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀ ⠀ ⠀ ⠀ ⠀⠀
                    </Text>
                  )}
                </HStack>
                <Text py={3}>
                  {firstLettersToUppercase(testimonial.person.split(' '))}
                </Text>
                {testimonial.videoUrl && (
                  <VideoModal
                    src={testimonial.videoUrl as string}
                    button={
                      <Text as={'button'} color='primary' fontWeight={700}>
                        {'Assistir Depoimento →'}
                      </Text>
                    }
                  />
                )}
                {!testimonial.videoUrl && <Box h={27} />}
              </VStack>
            </VStack>
          </SimpleGrid>
        </SlideFade>
      </motion.div>
    </>
  )
}
