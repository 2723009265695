import { Container, SlideFade, Text, VStack } from '@chakra-ui/react'
import { Section } from 'common/section'
import { FC, useState } from 'react'
import { Testimonial } from 'testimonial/entities/testimonial'
import { TestimonialItem } from '../common/testimonial_item'

interface Props {
  testimonials: Testimonial[]
}

export const Testimonials: FC<Props> = props => {
  const { testimonials = [] } = props

  const [index, setIndex] = useState(0)
  const testimonial = testimonials[index]

  const onPrevious = () => {
    setIndex(index === 0 ? testimonials.length - 1 : index - 1)
  }

  const onNext = () => {
    setIndex(index === testimonials.length - 1 ? 0 : index + 1)
  }

  if (!!!testimonials) return null

  return (
    <Section>
      <Container maxW={'7xl'}>
        <VStack spacing={10} direction={'column'} align={'flex-start'}>
          <Text textStyle={'overline'}>Depoimentos</Text>
          <Text textStyle={'subtitle'} textAlign={'center'}>
            O que nossos clientes estão falando
          </Text>
          <SlideFade in>
            {testimonial && (
              <TestimonialItem
                key={
                  testimonial.videoUrl
                    ? testimonial.videoUrl
                    : testimonial.avatar
                    ? testimonial.avatar
                    : testimonial.company
                }
                testimonial={testimonial}
                onPrevious={onPrevious}
                onNext={onNext}
              />
            )}
          </SlideFade>
        </VStack>
      </Container>
    </Section>
  )
}
