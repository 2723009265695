import { Box, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { DateTime } from 'luxon'
import Image from 'next/image'
import { BsInstagram, BsYoutube } from 'react-icons/bs'
import { MdFacebook } from 'react-icons/md'
import { addCdn } from 'utils/env'
import { SocialButton } from './social_button'

export default function Footer() {
  const currentYear = DateTime.now().year

  return (
    <VStack py={16} spacing={8} px={[6, 16]}>
      <VStack w={'full'} spacing={0}>
        <HStack spacing={[4, 12]} w={'full'} justify={'space-between'}>
          <Image
            width={150}
            height={41}
            src={addCdn('/images/logos/logo_berrytech.svg')}
          />
          <SimpleGrid columns={3} spacing={2}>
            <SocialButton
              icon={MdFacebook}
              url={'https://www.facebook.com/berrytechbrasil'}
            />
            <SocialButton
              icon={BsInstagram}
              url={'https://www.instagram.com/decolaberrytech'}
            />
            <SocialButton
              icon={BsYoutube}
              url={'https://youtube.com/c/Berrytech'}
            />
          </SimpleGrid>
        </HStack>
        <Box h={4} />
        <Text textAlign={'center'} textStyle={'caption'}>
          37.678.004/0001-33 BERRYCONSUL GESTAO DE FRANQUIAS LTDA
        </Text>
        <Text textAlign={'center'} textStyle={'caption'}>
          Rua XV de Novembro, 1117 - Centro, Curitiba - PR, 80060-000
        </Text>
        <Box h={4} />
        <Text textAlign={'center'} textStyle={'caption'}>
          Curitiba/PR ® 2015-{currentYear} Berrytech - Todos os direitos
          reservados
        </Text>
        <Text textStyle={'caption'}>Built with a lot of ❤ by berries</Text>
      </VStack>
    </VStack>
  )
}
