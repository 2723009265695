import { Container, ContainerProps, Flex, Stack } from '@chakra-ui/react'
import { FC } from 'react'

interface Props extends ContainerProps {
  containerMaxW?: string
}

export const Section: FC<Props> = props => {
  const { children, ...rest } = props
  return (
    <Flex w='full' py={{ base: 16, md: 24 }} px={{ base: 2, md: 12 }} {...rest}>
      <Container
        maxW={props.containerMaxW ? props.containerMaxW : 'container.xl'}
      >
        <Stack spacing={{ base: 8, md: 14 }} w='full'>
          {children}
        </Stack>
      </Container>
    </Flex>
  )
}
