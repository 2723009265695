import { useBoolean } from '@chakra-ui/hooks'
import { useMediaQuery } from '@chakra-ui/react'
import { useEffect } from 'react'

export const isIntersecting = (ref: any, threshold?: number) => {
  if (!ref) return false
  const [intersected, setIntersected] = useBoolean(false)

  const currentThreshold = threshold || 0.2
  const rootMargin = '0px'

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIntersected.on()
        }
      },
      { threshold: currentThreshold, rootMargin },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
        setIntersected.off()
      }
    }
  }, [threshold, rootMargin])

  return intersected
}

export const useMaxWidth = (maxWidth: string) => {
  const [maxW] = useMediaQuery(`(max-width: ${maxWidth})`)
  return maxW
}
