import { chakra, Flex, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { Section } from 'common/section'
import { addCdn } from 'utils/env'

export const AboutUs = () => {
  return (
    <Section bg={'backgroundAlt'}>
      <SimpleGrid templateColumns={['1fr', '1fr 2fr']} spacing={8} w={'full'}>
        <Flex flex={1}>
          <Image
            alt={'Ilustração que mostra crescimento'}
            src={addCdn('/images/illustrations/growth.svg')}
            loading={'lazy'}
          />
        </Flex>
        <VStack
          flex={4}
          spacing={10}
          justify={'flex-start'}
          w={'full'}
          align={'flex-start'}
        >
          <Text textStyle={'overline'}>Propósito</Text>
          <Text textStyle={'subtitle'}>
            Por um Brasil melhor e mais{' '}
            <chakra.span color={'primary'}>empreendedor</chakra.span>
          </Text>
          <Text>
            Somos jovens, com energia, uma cultura forte, muito conhecimento e
            com uma paixão inesgotável por empreendedorismo e ajudar nossos
            clientes. Nosso propósito é{' '}
            <chakra.span fontWeight={'medium'}>
              transformar o Brasil em um país melhor e mais empreendedor.
            </chakra.span>
          </Text>
        </VStack>
      </SimpleGrid>
    </Section>
  )
}
