import { IconButton } from '@chakra-ui/react'
import { FC } from 'react'
import { IconType } from 'react-icons'

interface SocialButtonProps {
  icon: IconType
  url: string
  color?: string
}

export const SocialButton: FC<SocialButtonProps> = props => {
  const { url, color } = props

  const iconColor = color

  return (
    <IconButton
      onClick={() => window.open(url, '_blank')}
      aria-label='instagram'
      variant='ghost'
      size='lg'
      isRound={true}
      color={iconColor || 'heading'}
      _hover={{ color: 'white', bg: 'primary' }}
      icon={<props.icon size='28px' />}
    />
  )
}
