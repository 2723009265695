import { Button } from '@chakra-ui/react'
import { FC } from 'react'

interface Props {
  label?: string
  
}

export const CallToAction: FC<Props> = props => {
  const { label } = props
  return (
    <Button variant={'primary'} py={4} px={6} size={'xl'}>
      <a href="https://berry.tech">
      {label || 'Solicitar mais informações'}
      </a>
    </Button>
  )
}
